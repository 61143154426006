

// useWetixCookie is just shorthand for useCookie('wetix-token')
export const useWetixCookie = () => useCookie('wetix-token')

// Returns ID token string for use in Bearer {token} authorization header on HTTP requests to back-end. 
export const useWetixIdToken = async () => {
    // During SSR, always get the token from cookie
    if (useNuxtApp().ssrContext) return useCookie('wetix-token').value
    // Otherwise, get the token from firebase auth
    const { $firebaseAuth } = useNuxtApp()
    return await $firebaseAuth.currentUser.getIdToken(/* forceRefresh */ false)
}


// Calls the Wetix API with authorization header added to the request.
export const wetixFetch = async (url: string, fetchOptions: any = {}) => {

    const config = useRuntimeConfig()

    // Get a firebase auth token
    const { $firebaseAuth } = useNuxtApp()

    const userIdToken = await $firebaseAuth.currentUser.getIdToken(/* forceRefresh */ false)

    return await $fetch(url, {
        baseURL: `${config.WETIX_SERVICE_API_BASE_URL}`,
        ...fetchOptions,
        headers: {
            Authorization: `Bearer ${userIdToken}`,
            ...fetchOptions.headers,
        },
    })
}


// Gets a fresh firebase auth token
export const useFirebaseAuthToken = async () => {
    const { $firebaseAuth } = useNuxtApp()
    return await $firebaseAuth.currentUser.getIdToken(/* forceRefresh */ false)
}